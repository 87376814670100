// bridge 自带多链，独立于 contract 配置
import {
    OnBlock,
    WeiToUnits,
    EtherToWei,
    Erc20,
    MultiCall,
    CreateProviderForRpc,
    GetGlobalSigner,
    CreateContract,
    DEFAULT_ACCOUNT,
    BN,
    // SignApproves,
    SwitchChain,
    Approves,
    BigToString
} from 'ethers-easy'
// } from '../../ethers/e'

import { useEffect, useState } from "react";

import bridgeConfig from './bridgeConfig.json'
// import bridgeConfig from './bridgeConfig.dev.json'
import IBridge from './IBridge.json'
import IAssetProxy from './IAssetProxy.json'

import {useEthers} from '../../ethers'
import useButtonText from '../useButtonText'
import useInput from '../useInput';
import useToast from '../useToast'

let providerRpc = null
let bridgeContract = null
let multiCall = null
let defaultToken = null
let assetProxy = null
function createProvider () {
    if ( bridgeContract !== null ) return
    providerRpc = {}
    bridgeContract = {}
    multiCall = {}
    defaultToken = {}
    assetProxy = {}
    Object.keys(bridgeConfig).forEach(chainId => {
        const _config = bridgeConfig[chainId]
        const _provider = CreateProviderForRpc(_config.RPC[0])
        providerRpc[chainId] = _provider
        bridgeContract[chainId] = CreateContract(_config.Bridge, IBridge, _provider)
        multiCall[chainId] = MultiCall(_config.MultiCall, _provider)
        defaultToken[chainId] = Erc20(_config.DefaultCoin, _provider)
        assetProxy[chainId] = CreateContract(_config.AssetProxy, IAssetProxy, _provider)
    })
}
/**
* _order type
* chainId: number
* oId: number
* blockNumber: number
* inputToken: address
* inputWei: string
* toChainId: number
* to: address
* fee: string
* withdraw: null | hash
*/
let _ORDER = {}
// const CoinWeiToEther = (wei, decimals) => {
//     return BN(wei).div(BN(10).pow(decimals)).toString()
// }
const e = n => BN(10).pow(n)
const GetOutputTokenWei = (fromChainId, fromToken, toChainId, toToken, inputTokenWei, feeWei) => {
    try {
        const _fromToken = bridgeConfig[fromChainId].Coin[fromToken]
        const _toToken = bridgeConfig[toChainId].Coin[toToken]
        const rate = _fromToken.ChangeRate[toChainId][toToken]

        // 过滤数量超出范围的订单
        if (BN(inputTokenWei).lt(_fromToken.MinWei) || BN(inputTokenWei).gt(_fromToken.MaxWei)) return 0

        return BN(inputTokenWei).sub(feeWei).mul(rate * 1e4).div(1e4).mul(e(_toToken.Decimals)).div(e(_fromToken.Decimals)).toString()
    } catch (error) {
        // 没有配置的 token 返回 0
        console.log(error)
        return 0
    }
}
const checkToken = ({
    chainId,
    inputToken,
    toChainId,
    outputToken,
    inputAmount
}) => {
    try {
        // toChainId = toChainId.toString(10)
        const _fromToken = bridgeConfig[chainId].Coin[inputToken]
        const _toToken = bridgeConfig[toChainId].Coin[outputToken]
        const minInput = _fromToken.MinWei
        const maxInput = _fromToken.MaxWei
        if ( BN(inputAmount).lt(minInput) ) return `low then ${minInput}`
        else if ( BN(inputAmount).gt(maxInput) ) return `more then ${maxInput}`
        else if ( _toToken === undefined ) return `to token not found`
        else if ( _fromToken === undefined ) return `from token not found`
        return {
            inputToken: _fromToken,
            outputToken: _toToken
        }
    } catch (error) {
        console.log(`not token`)
        return null
    }
}
// 初始化订单
async function initBridgeOrder(account, chainId) {
    if ( account === DEFAULT_ACCOUNT) return
    chainId = chainId*1
    const _bridgeContract = bridgeContract[chainId]
    // const _tokenBalance = defaultToken[chainId]
    // 获取订单
    let calls = BigToString(
        await multiCall[chainId].all({
            // userBalance: _tokenBalance.calls.balanceOf(account),
            // bridgeBalance: _tokenBalance.calls.balanceOf(_bridgeContract.address),
            userOrder: _bridgeContract.calls.orderOfUser(account),
            orderIds: _bridgeContract.calls.orderIdsOfUser(account),
            // nowBlock: multiCall[chainId].calls.
        })
    )
    // 查询已完成订单
    const _blockNumber_ = calls._blockNumber_
    const _withdrawOrder = {}

    calls.userOrder.forEach((order, i) => {

        const toChainId = order.toChainId

        const oId = calls.orderIds[i]

        const orderKey = chainId + "_" + oId
        if ( _ORDER[orderKey] === undefined ) {
            const tokens = checkToken({ chainId, ...order })
            if ( !tokens ) return
            const {
                blockNumber,
                inputToken,
                inputAmount: inputWei,
                outputToken,
                toChainId,
                fee: feeWei,
                to
            } = order
            const _inputTokenDetail = tokens.inputToken
            const _outputTokenDetail = tokens.outputToken
            const _outputAmount = WeiToUnits(GetOutputTokenWei(chainId, inputToken, toChainId, outputToken, inputWei, feeWei), _outputTokenDetail.Decimals)
            _ORDER[orderKey] = {
                chainId,
                oId,
                blockNumber,
                inputToken,
                inputName: _inputTokenDetail.Name,
                inputAmount: WeiToUnits(inputWei, _inputTokenDetail.Decimals),
                outputToken,
                outputName: _outputTokenDetail.Name,
                outputAmount: _outputAmount,
                toChainId,
                fee: WeiToUnits(feeWei, _inputTokenDetail.Decimals),
                to,
                withdraw: null
            }
        }
        if ( _ORDER[orderKey] ) {
            _ORDER[orderKey].confirmBlock = _blockNumber_ - _ORDER[orderKey].blockNumber
        }
        if ( _ORDER[orderKey] && _ORDER[orderKey].withdraw === null ) {
            if ( _withdrawOrder[toChainId] === undefined ) {
                _withdrawOrder[toChainId] = []
            }
            _withdrawOrder[toChainId].push([
                chainId,
                oId,
            ])
        }
    })
    const _withdrawKeys = Object.keys(_withdrawOrder)
    const withdrawCalls = await Promise.all(
        _withdrawKeys.map(toChainId => {
            const _bridge = bridgeContract[toChainId]
            return _bridge.checkWithdrawOrders(_withdrawOrder[toChainId])
        })
    )
    withdrawCalls.forEach((withdraws, i) => {
        const toChainId = _withdrawKeys[i]
        const _wIds = _withdrawOrder[toChainId]
        withdraws.forEach((withdraw, j) => {
            const blockNubmer = withdraw.blockNumber
            if ( blockNubmer.gt(0) ) {
                const [fromChainId, oId] = _wIds[j]
                const orderKey = fromChainId + "_" + oId
                if ( _ORDER[orderKey] ) {
                    _ORDER[orderKey].withdraw = BigToString(blockNubmer)
                }
            }
        })
    })
}
// 初始化 token 余额
async function initTokenBalance(account, chainId) {
    // const _bridgeContract = bridgeContract[chainId]
    const _assetAddress = bridgeConfig[chainId].AssetProxy
    const _tokenBalance = defaultToken[chainId]
    // console.log(_assetAddress, chainId, _tokenBalance.address)
    // 获取订单
    let calls = BigToString(
        await multiCall[chainId].all({
            userBalance: _tokenBalance.calls.balanceOf(account),
            bridgeBalance: _tokenBalance.calls.balanceOf(_assetAddress)
        })
    )

    // console.log(calls)
    const tokenAddress = _tokenBalance.address
    const tokenDetail = bridgeConfig[chainId].Coin[tokenAddress]
    const decimals = tokenDetail.Decimals
    const name = tokenDetail.Name
    const maxInput = WeiToUnits(tokenDetail.MaxWei, decimals)
    const minInput = WeiToUnits(tokenDetail.MinWei, decimals)
    const fee = 0.005
    const bridgeBalance = WeiToUnits(calls.bridgeBalance, decimals)
    const balance = WeiToUnits(calls.userBalance, decimals)

    // console.log(chainId, bridgeBalance)
    return {
        chainId,
        tokenAddress,
        name,
        decimals,
        maxInput: maxInput,
        minInput,
        balanceInsufficient: bridgeBalance < minInput,
        fee,
        balance,
        bridgeBalance
    }
}

////////////////// hooks ///////////////////////
const _DEFAULT_EXCHANGE_PAIR = {
    813: 56,
    56: 813
}
const TOKEN_BALANCE = {
    chainId: 0,
    tokenAddress: '',
    decimals: 18,
    name: '',
    maxInput: 0,
    minInput: 0,
    fee: 0,
    balance: '--',
    bridgeBalance: 0
}
const BRIDGE_TOKEN_INIT = {
    56: TOKEN_BALANCE,
    813 : TOKEN_BALANCE
}
export function useBridgeOrder() {
    const {account, chainId} = useEthers()
    const toChainId = _DEFAULT_EXCHANGE_PAIR[chainId]
    const [tokenBalance, setBalance] = useState(BRIDGE_TOKEN_INIT)
    const [, setRefresh] = useState(false)
    // const 
    useEffect(() => {
        createProvider()
    }, [])

    useEffect(() => {
        const _listen = []
        if (account === DEFAULT_ACCOUNT) return
        for(let _chainId in bridgeConfig) {
            const {stop} = OnBlock(
                providerRpc[_chainId],
                async () => {
                    initTokenBalance(account, _chainId).then(bal => {
                        // console.log(bal, _chainId, "initTokenBalance")
                        setBalance(v => ({
                            ...v,
                            [bal.chainId*1]: bal
                        }))
                    })
                    await initBridgeOrder(account, _chainId)
                    setRefresh(v => !v)
                }
            )
            _listen.push(stop)
        }
        return () => {
            _listen.forEach(v => v())
            _ORDER = {}
        }
    }, [account])

    const chooseFromChain = async (chainId) => {
        chainId = chainId*1
        const _config = bridgeConfig[chainId]
        const nativeCurrency = _config.NativeCurrency
        try {
            await SwitchChain({
                chainId: "0x" + chainId.toString(16),
                chainName: nativeCurrency.name,
                nativeCurrency,
                rpcUrls: _config.RPC[0],
                blockExplorerUrls: _config.Explorer
            })
        } catch (e) {
            console.log(e)
            alert(e.message)
        }        
    }
    const isChainIdError = !_DEFAULT_EXCHANGE_PAIR[chainId]
    const maxInput = isChainIdError ? 0 : Math.min(tokenBalance[chainId].maxInput * 1, tokenBalance[toChainId].bridgeBalance  * 1)
    // console.log(tokenBalance[toChainId].bridgeBalance, tokenBalance[chainId].maxInput, toChainId, " maxInput")
    const insufficient = isChainIdError ? 0 :  tokenBalance[chainId].minInput > maxInput
    return {
        chooseFromChain,
        from: {
            chainId,
            tokenBalance: tokenBalance[chainId]
        },
        to: {
            chainId: toChainId,
            tokenBalance: tokenBalance[toChainId]
        },
        maxInput,
        insufficient,
        orders: Object.values(_ORDER).sort((a, b) => b.oId - a.oId).filter(v => v.chainId === chainId),
        estimateOutput: (inputAmount) => {
            const inToken = tokenBalance[chainId]
            const outToken = tokenBalance[toChainId]
            const inputWei = EtherToWei(inputAmount, inToken.decimals)
            const feeWei = EtherToWei(inputAmount*inToken.fee, inToken.decimals)
            return WeiToUnits(GetOutputTokenWei(chainId, inToken.tokenAddress, toChainId, outToken.tokenAddress, inputWei, feeWei), outToken.decimals)
        }
    }
}

export function useExchange() {
    const {account, chainId} = useEthers()
    const inputAmount = useInput("", {type: "number", placeholder: "0.0"})
    const {open} = useToast()
    const {
        button,
        loadingButton,
        initButton,
        // setButtonText,
        // setDisabled
    } = useButtonText("Exchange")

    button.onClick = async () => {
        loadingButton("Pedding")
        const signer = GetGlobalSigner()

        const fromChainId = chainId
        const toChainId = _DEFAULT_EXCHANGE_PAIR[chainId]
        const _config = bridgeConfig[fromChainId]
        const _bridgeContract = bridgeContract[fromChainId]
        const _tokenAddress = _config.DefaultCoin
        const _tokenDetaill = _config.Coin[_tokenAddress]
        const _toConfig = bridgeConfig[toChainId]
        const _toTokenAddress = _toConfig.DefaultCoin
        // const _provider = providerRpc[fromChainId]
        const _amountWei = EtherToWei(inputAmount.value, _tokenDetaill.Decimals)

        // 这里可以封装成组建
        let {
            nonce,
            tx,
            error
        } = await Approves(
            [
                {
                    token: [_tokenAddress, _tokenDetaill.Decimals, _tokenDetaill.Name],
                    amountWei: _amountWei,
                    sender: _bridgeContract.address,
                    isMax: true,
                    needClear: true
                }
            ],
            {
                provider: signer,
                callback({
                    // status,
                    // token,
                    error
                }) {
                    // console.log("callback ", result)
                    // setButtonText("Approve")
                    if ( error ) {
                        open("Approve Console", "error")
                    } else {
                        open("Approve Success")
                    }
                    
               }
            }
        )
        
        try {
            if ( !error ) {
                if (fromChainId*1 === 56) await tx.wait()
                tx = await _bridgeContract.connect(signer).addOrder(
                    toChainId,
                    _tokenAddress,
                    account,
                    _toTokenAddress,
                    _amountWei,
                    {nonce: nonce}
                )
                await tx.wait()
                open("Create Order Success")
            }
        } catch (e) {
            // console.log(e)
            open("Console Oreder", "error")
        }
        // setButtonText("Pedding")
        // await tx.wait()
        // console.log("nonce ", nonce)
        initButton()
    }

    return {
        button,
        inputAmount
    }
}

// const consoleTittle = title => console.log('\x1b[43m\x1b[30m%s\x1b[0m', `-------------- ${title} --------------`)
/////////////////////// transaction detail ///////////////////////
/**
 * 
 */
async function GetTxByBlock(blockNumber, chainId) {
    // const signer = GetGlobalSigner()
    blockNumber = blockNumber*1
    const _provider = providerRpc[chainId]
    const _blockTx = await _provider.getBlockWithTransactions(blockNumber)
    const _bridgeContract = bridgeContract[chainId]
    const _bridgeSigHash = _bridgeContract.sighash
    const _to = _bridgeContract.address

    const _detail = []
    _blockTx.transactions.forEach(tx => {
        const {hash, to, data, from} = tx
        if ( to !== _to ) return
        const _sighashFromData = data.slice(0, 10)
        const _bHash = _bridgeSigHash[_sighashFromData]
        if ( _bHash ) {
            const _input = _bHash.decode(data)
            _detail.push({
                hash,
                from,
                input: _input,
                method: _bHash.method
            })
        }
    })

    const _events = _bridgeContract.events
    const _calls = await Promise.all(_detail.map(v =>  _provider.getTransactionReceipt(v.hash)))
    _calls.forEach((v,i) => {
        const _de = _detail[i]
        _de.logs = []
        v.logs.forEach(v1 => {
            const _topic = v1.topics[0]
            const _eventFun = _events[_topic]
            if ( !_eventFun ) return
            _de.logs.push({
                arg: _eventFun.decode(v1.data, v1.topics),
                event: _eventFun.event
            })
        })
    })
    return _detail
}

const ORDER_DETAIL = {
    fromTxHash: 'pending',
    toTxHash: 'pending',
    toChainId: 0,
    to: ''
}
const getDetail = async (blockNumber, chainId, oId) => {
    const detail = BigToString(await GetTxByBlock(blockNumber, chainId))
    if (!oId) return detail
    return detail.filter(v => {
        return v.logs.find(v => v.event === "OrderIn" && v.arg.orderId * 1 === oId*1 )
    })
}

/**
 * 
 * @param {string} chainId 
 * @param {string} fromChainId 
 * @param {string} oId 
 * @returns {proimse<null | string>}
 */
const getWithrawHash = async (chainId, fromChainId, oId) => {
    const _bridgeContract = bridgeContract[chainId]
    const _withDetail_ = await _bridgeContract.checkWithdrawOrder(fromChainId, oId);
    const withdrawBlock = _withDetail_.blockNumber.toString()
    if ( withdrawBlock === '0' ) return null
    const detail = BigToString(await GetTxByBlock(withdrawBlock, chainId))
    const _fdetail = detail.find(v => {
        return v.logs.find(v => v.event === "WithdrawOut" && v.arg.fromChainId * 1 === fromChainId*1 && v.arg.fromOrderId * 1 === oId*1 )
    })
    return !_fdetail ? null : _fdetail.hash
}

export function useOrderDetail(blockNumber, chainId, oId) {

    const [detail, setDetail] = useState(ORDER_DETAIL)

    useEffect(() => {
        getDetail(blockNumber, chainId, oId).then(detail => {
            detail = detail[0]
            const fromTxHash = detail.hash
            const toChainId = detail.input.toChainId
            const to = detail.input.to
            const toTxHash = "pending"
            setDetail({
                fromTxHash,
                toChainId,
                to,
                toTxHash
            })
        })
    },[])

    useEffect(() => {
        if ( !detail.toChainId ) return
        const {stop} = OnBlock(
            providerRpc[detail.toChainId],
            async () => {
                const toTxHash = await getWithrawHash(detail.toChainId, chainId,  oId)
                if (!toTxHash) return
                setDetail(v => ({
                    ...v,
                    toTxHash
                }))
                stop()
            }
        )
        return () => {
            stop()
        }
    }, [detail.toChainId, chainId, oId])

    return {
        detail,
        toExplorer: () => {
            if (detail.toTxHash === 'pending') return
            const _config = bridgeConfig[detail.toChainId]
            window.open(_config.Explorer + "/tx/" + detail.toTxHash)
        },
        fromExplorer: () => {
            if (detail.fromTxHash === 'pending') return
            const _config = bridgeConfig[chainId]
            window.open(_config.Explorer + "/tx/" + detail.fromTxHash)
        }
    }
}

// asset 资产管理