import { styled } from '@mui/material/styles'; 

const scriptElem = document.createElement('script');
scriptElem.src =  './icon.js'//ALI_ICON;
document.body.appendChild(scriptElem);

function SuperIcon({ className, c, ...restProps }) {
  return (
    <svg
      className={className}
      aria-hidden="true"
      {...restProps}
    >
      <use xlinkHref={`#${c}`} />
    </svg>
  );
}

const IconStyled = styled(SuperIcon)`
  width: ${props => props.size ? props.size+ 'px' : '22px'};
  height: ${props => props.size ? props.size+ 'px' : '22px'};
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
  min-width: ${props => props.size ? props.size+ 'px' : '22px'};
`

export default IconStyled;

export const Nothing = ({className}) => <SuperIcon className={className} size='1.2' c='icon-kongshuju'/>
export const NoData = (prop) => <IconImg {...prop} src='./nodata.png'/>

export const TipsIcon = ({c, ...other}) => <IconStyled size='1' style={{marginLeft:'.2rem',verticalAlign:'0.15rem',color: 'rgb(114, 39, 35)'}} {...other} c={c}/>

export const I = (other) => <IconStyled style={{marginLeft:'4px',verticalAlign:'0',width:'16px'}} {...other} c='icon-prompt'/>

export const IconImg = styled('img')`
  width: ${props => props.size ? props.size+ 'px' : '22px'};
  height: ${props => props.size ? props.size+ 'px' : '22px'};
  display: inline-block;
  border-radius: 8px;
  vertical-align: middle;
`