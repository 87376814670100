import {styled} from '@mui/material/styles'
import Icon, {NoData} from '../../components/Icon'
import Logo from '../../components/Logo'
import { 
    Button,
    TextL,
    TextS,
    TextM,
    TextLinear,
    WhiteSpace,
    Card,
    Input,
    Tips
 } from '../../components'
import {
    WalletButton
} from '../../ethComponents'

import { useEthers } from '../../ethers'
import {
    useBridgeOrder,
    useExchange,
    useOrderDetail
} from '../../hook/bridge/useBridge'
import useDialog from '../../hook/useDialog';


import {
    ShortAddress,
    EtherToInt
} from 'ethers-easy'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
// import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';

import Dialog from '@mui/material/Dialog';

import NetError from '../NetError'

function Address() {
    const { account } = useEthers()
    const buttonAttar = {
        size: 'small',
        type: 'd'
    }
    return (
        <WalletButton {...buttonAttar}>
            <Button {...buttonAttar}>{ShortAddress(account)}</Button>
        </WalletButton>
    )
}

function AppBar() {
    return (
        <>
            <MuiAppBar >
                <Container sx={{marginTop:'10px'}}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Logo size='1.6'> <TextL style={{marginLeft: '6px'}}> {process.env.TITLE} </TextL></Logo>
                        <Address />
                    </Stack>
                </Container>
            </MuiAppBar>
            <Toolbar />
        </>
    );
}



const CHAIN_ICON = {
    56: 'icon-celvexiaotubiao-02',
    813: 'icon-meer',
}
function Bridge1() {
    const bridge = useBridgeOrder()
    const {
        button,
        inputAmount
    } = useExchange()
    const {
        dialog,
        openDialogWithChildren
    } = useDialog()
    const setMaxFrom = () => {
        inputAmount.onChange(Math.min(bridge.maxInput * 1, bridge.from.tokenBalance.balance * 1))
    }
    const handleSwitch = () => bridge.chooseFromChain(bridge.to.chainId)
    const disabledMin = inputAmount.value !== '' && inputAmount.value * 1 < bridge.from.tokenBalance.minInput * 1
    const disabledMax = inputAmount.value !== '' && inputAmount.value * 1 > bridge.maxInput * 1

    // console.log(inputAmount.value, bridge.from.tokenBalance.minInput)
    return (
        <>
            <AppBar />
            <WhiteSpace />
            <Container>
                <Card type='c' spacing={.6}>
                    <TextL>Bridge</TextL>
                    <br />
                    <TextS style={{ marginTop: '2px' }} color={2}>Easy way to Multiple Chain Exchange</TextS>
                    <WhiteSpace />
                    
                    <Stack direction='row' alignItems='center' sx={{
                        background: '#242625',
                        padding: '10px 8px',
                        borderRadius: '6px',
                    }}>
                        <Icon style={{marginRight: '2px'}} size='36' c={CHAIN_ICON[bridge.from.chainId]} />
                        <div style={{width:'100%', marginLeft: '6px'}} >
                            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{padding:'0xp 6px'}}>
                                <TextS color='1' >From</TextS>
                                <TextS color='1' onClick={setMaxFrom} >
                                    Balance: <TextLinear size='0'>{EtherToInt(bridge.from.tokenBalance.balance)}</TextLinear> <Tips style={{marginLeft: '4px'}}>Max</Tips>
                                </TextS>
                            </Stack>
                            <Input
                                style={{padding: '0px', textAlign: 'end'}}
                                inputStyle={{textAlign: 'end'}}
                                // square
                                before={<TextM>{bridge.from.tokenBalance.name || <SkeletonText />}</TextM>}
                                // after={<TextM>{bridge.from.tokenBalance.name}</TextM>}
                                input={inputAmount}
                            />
                        </div>
                    </Stack>
                    <SwitchToken
                        onClick={handleSwitch}
                        // src='/exchange.png'
                    >
                        <Icon c='icon-jiaohuan' size='24'/>
                    </SwitchToken>
                    <Stack direction='row' alignItems='center' sx={{
                        background: '#242625',
                        padding: '10px 8px',
                        borderRadius: '6px',
                    }}>
                        <Icon style={{marginRight: '2px'}} size='36' c={CHAIN_ICON[bridge.to.chainId]} />
                        <div style={{width:'100%', marginLeft: '6px'}} >
                            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{padding:'0xp 6px'}}>
                                <TextS color='1' >To</TextS>
                                <TextS color='1' onClick={setMaxFrom} >
                                    Balance: <TextLinear size='0'>{EtherToInt(bridge.to.tokenBalance.balance)}</TextLinear>
                                </TextS>
                            </Stack>
                            <Input
                                style={{padding: '0px'}}
                                inputStyle={{textAlign: 'end'}}
                                // square
                                before={
                                    // bridge.to.tokenBalance.name ?
                                    <TextM>{bridge.to.tokenBalance.name || <SkeletonText />}</TextM>
                                    
                                }
                                // after={<TextM>{bridge.from.tokenBalance.name}</TextM>}
                                input={inputAmount}
                            />
                        </div>
                    </Stack>
                    <WhiteSpace />
                    <Stack direction='row' justifyContent='space-between' alignItems='center' >
                        <TextS color='2'>Max Amount</TextS>
                        <TextS>{EtherToInt(bridge.maxInput)}</TextS>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' >
                        <TextS color='2'>Min Amount</TextS>
                        <TextS>{EtherToInt(bridge.from.tokenBalance.minInput)}</TextS>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' >
                        <TextS color='2'>Fee Rate</TextS>
                        <TextS>{bridge.from.tokenBalance.fee * 100}%</TextS>
                    </Stack>
                    <WhiteSpace />
                    {
                        disabledMin && <TextS style={{color:"red", margin: "0px 0 2px 0"}} >Minimum {bridge.from.tokenBalance.minInput}</TextS>
                    }
                    {
                        disabledMax && <TextS style={{color:"red", margin: "0px 0 2px 0", display:'block'}} >Maxmun {bridge.maxInput}</TextS>
                    }
                    <Button size='large' fullWidth {...button} disabled={disabledMin || disabledMax || inputAmount.value === ''} />
                    <WhiteSpace />
                </Card>
                <WhiteSpace />
                <Card type='c' spacing={.6}>
                    <TextM>Orders</TextM>
                    <br />
                    {
                        bridge.orders.length === 0 ?
                        <NoData size='60' style={{opacity: 0.6, margin: '16px auto', display: 'block'}} /> :
                        bridge.orders.map((o) => (
                            <Card type='e' spacing={[.4, 0, 0]} sx={{marginTop: '8px'}} key={`${o.chainId}_${o.oId}`} onClick={() => {
                                openDialogWithChildren(
                                    <TxDetail
                                        blockNumber={o.blockNumber}
                                        chainId={o.chainId}
                                        oId={o.oId}
                                    />
                                )
                            }}>
                                <Stack sx={{padding: '4px 8px'}} direction='row' justifyContent='space-between' alignItems='center'>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center' justify='start' style={{flex: 3}}>
                                        <Icon size='30' c={CHAIN_ICON[o.chainId]} />
                                        <div style={{marginLeft: '12px', textAlign: 'right'}}>
                                            <TextS color="1">{o.inputName}</TextS>
                                            <br />
                                            <TextM>{EtherToInt(o.inputAmount)}</TextM>
                                        </div>
                                    </Stack>
                                    <Icon c='icon-xiangyou' color='#dcfc35' size='18' style={{margin: '0px 12px'}}/>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center' justify='end' style={{flex: 3}}>
                                        <div style={{marginRight: '12px'}}>
                                            <TextS color="1">{o.outputName}</TextS>
                                            <br />
                                            <TextM>{EtherToInt(o.outputAmount)}</TextM>
                                        </div>
                                        <Icon size='30' c={CHAIN_ICON[o.toChainId]} />
                                    </Stack>
                                </Stack>
                                <PendingDetail close={o.withdraw}>
                                    <TextS color='3'>Confirm Block {o.confirmBlock}</TextS>
                                    <TextS color='3'>{o.withdraw === null ? "pending":"Done"}</TextS>
                                </PendingDetail>
                            </Card>
                        ))
                    }
                <Dialog {...dialog}/>
                {/* <Dialog {...dialog}>
                    llllll111l1l1
                </Dialog> */}
                </Card>
            </Container>
        </>
    )
}

function Bridge() {
    const {chainId} = useEthers()
    const isChainError = !CHAIN_ICON[chainId]
    // console.log(isChainError)

    return (
        isChainError?
        <NetError/>:
        <Bridge1 />
    )
}

export default Bridge

function TxDetail({blockNumber, chainId, oId}) {
    const {
        detail,
        toExplorer,
        fromExplorer
    } = useOrderDetail(blockNumber, chainId, oId)
    return (
        <Card type='c' spacing={.8}>
            <TextM>Hash Detail</TextM>
            <WhiteSpace />
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <TextS>From Hash</TextS>
                <div onClick={fromExplorer}>
                    <TextS
                        style={{
                            maxWidth: "140px",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}
                    >{detail.fromTxHash === 'pending' ? detail.fromTxHash : ShortAddress(detail.fromTxHash, 8)}</TextS>
                    {/* <SwitchToken w='28' src="share.png" /> */}
                    <Icon c='icon-tiaozhuan-zhuanqu' size='12' color='#dcfc35' style={{marginLeft: '6px'}}/>
                </div>
            </Stack>
            {/* <br /> */}
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <TextS>To Hash</TextS>
                <div onClick={toExplorer}>
                    <TextS
                        style={{
                            maxWidth: "140px",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}
                    >{detail.toTxHash === 'pending' ? detail.toTxHash : ShortAddress(detail.toTxHash, 8)}</TextS>
                    <Icon c='icon-tiaozhuan-zhuanqu' size='12' color='#dcfc35' style={{marginLeft: '6px'}}/>
                </div>
            </Stack>
        </Card>    
    )
}


const SwitchToken = styled(Card)`
    position: relative;
    padding: 8px;
    margin: -4px auto;
    width: max-content;
    border-radius: 50%;
    z-index: 2;
`;

// const PendingBlock = styled('div')`
//     padding: 6px 8px;
//     border-radius: 10px;
//     /* background: rgb(6 6 6); */
//     margin-top: 12px;
// `;

const PendingDetail = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${p => p.close ? '#161515' : '#8287be'};
    padding: 2px 6px;
    /* border-radius: 4px; */
    margin-top: 6px;
`;

const SkeletonText = styled(Skeleton)`
    background: #39384a;
    width: 50px;
`