import { useSnackbar } from 'notistack'; 

// variant : 'default' | 'error' | 'success' | 'warning' | 'info';
function useToast( ) {
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (content) => {
    enqueueSnackbar(content);
  };

  const handleClickVariant = (content, variant='success') => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
        content
      , { variant, autoHideDuration: 1000 });
  };
  return {
    open: handleClickVariant,
    info: handleClick
  }
}

export default useToast