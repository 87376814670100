import { useState } from 'react';
// import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import Drawer from '@mui/material/Drawer';

import {
    Button,
    TextS,
    TextM,
    TextL,
    TextXL,
    TextMax,
    Card,
    Input
} from '../components';
import Logo from '../components/Logo';

import useToast from '../hook/useToast';
import useDialog, {useDrawer} from '../hook/useDialog';



function Theme() {
    const [loading, setLoading] = useState(false);
    const [a, st] = useState(0);
    const { open, info } = useToast();
    const { dialog, openDialog } = useDialog();

    const { dialog: dialog1, openDialog: openDialog1 } = useDialog();
    const {
        drawer,
        openDrawer,
        closeDrawer
    } = useDrawer('bottom')
    return (
        <div>
            <Logo />
            <h1 onClick={() => info(
                <Stack spacing={0}>
                    <TextL>useToast</TextL>
                    <TextM>useToast</TextM>
                </Stack>
            )}>Theme</h1>
            <Card>
                <TextS onClick={openDialog}> TextS</TextS>
                <br />
                <TextM onClick={openDialog1}>TextM</TextM>
                <br />
                <TextL onClick={openDrawer}>TextL</TextL>
                <br />
                <TextXL>TextXL</TextXL>
                <br />
                <TextMax>TextMax</TextMax>
            </Card>
            <Stack spacing={1}>
                <Card type='a'>A</Card>
                <Card type='b'>B</Card>
                <Card type='c'>C</Card>
                <Card type='d'>D</Card>
                <Card type='e'>E</Card>
                <Card type='f'>F</Card>
                <Card type='g'>G</Card>
            </Stack>
            
            
            <Button
                // variant="outlined"
                // variant="contained"
                // loading={loading}
                // disabled
                size='small'
                onClick={() => setLoading(v => !v)}
                fullWidth
                type='c'
            >
                TEst
            </Button>
            <br />
            <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
            >
                <Button type='a'>A</Button>
                <Button type='b'>B</Button>
                <Button type='c'>C</Button>
                <Button type='unActive'>D</Button>
            </Stack>
            <br />
            <Card>
                <TextL>L</TextL>
                <Input
                    input={{
                        required: true,
                        autoFocus: true
                    }}
                    before={<TextM>0x</TextM>}
                    after={<TextM>ETH</TextM>}
                />
            </Card>
            <Dialog {...dialog}>
                <Card>
                    <Stack spacing={1}>
                        <TextL onClick={() => {
                            console.log('click')
                            setLoading(v => !v)
                        }}>useToast {loading + ''}</TextL>
                        <TextM>useToast 1212</TextM>
                    </Stack>
                </Card>
            </Dialog>
            <Dialog {...dialog1}>
                <Card>
                    <Stack spacing={1}>
                        <TextL>useToast 1</TextL>
                        <TextM>useToast 1</TextM>
                    </Stack>
                </Card>
            </Dialog>
            <Drawer {...drawer}>
                <Card>
                    <Stack spacing={1}>
                        <TextL>useToast 1</TextL>
                        <TextM>useToast 1</TextM>
                    </Stack>
                </Card>
            </Drawer>
        </div>
    )
}

export default Theme;
