import {styled} from '@mui/material/styles';

function Logo({ children, size, ...other }) {
    return (
        <OutBlock {...other} onClick={() => {
            window.history.go(-1)
        }}>
            <LogoImg src="./logo.svg" size={size} />
            {children}
        </OutBlock>
    )
}

export default Logo

const default_w = '2.4'
const OutBlock = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* background: linear-gradient(90deg, #F3503C 0%, #FE06F0 100%); */
    opacity: 1;
    /* border-radius: 90px; */
`
const LogoImg = styled('img')`    
    width: ${p => p.size || default_w}rem;
    height: ${p => p.size || default_w}rem;
    display: block;
    border-radius: 100px;
    /* border: 2px solid #f4de9d82; */
    /* box-shadow: 0px 0px 16px #597358; */
`