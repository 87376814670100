import {
    // HashRouter as Router,
    BrowserRouter as Router,
    Route,
    // Link,
    // useHistory
} from "react-router-dom";

import Bridge from './Bridge';
import Theme from './Theme';


export const Done = [
    {
        title: 'Home',
        path: '/',
        exact: true,
        component: Bridge
    }
]

export const Dev = [
    
    // {
    //     title: 'Swap',
    //     path: '/network',
    //     exact: true,
    //     // href: 'https://dapp.xpslab.pro/',
    //     component: null,
    //     disabled: true
    // }
]

// 隐形路由
export const INVISIBLE = [
    {
        title: 'Theme',
        path: '/theme',
        exact: true,
        component: Theme
    },
    // {
    //     title: 'Hash',
    //     path: '/hash',
    //     exact: true,
    //     component: CheckHash
    // }
    // {
    //     title: 'uAdmin',
    //     path: '/uadmin',
    //     exact: true,
    //     component: UAdmin
    // },
    // {
    //     title: 'tokens',
    //     path: '/tokens',
    //     exact: true,
    //     component: ChooseToken
    // }
    
]

export const RouterView = () => {
    const flat = [];
    [...Done, ...Dev,...INVISIBLE].forEach(v => {
        const {child, ...other} = v
        flat.push(<Route key={other.title} {...other}/>)
        if ( child ) {
            child.forEach(v => {
                flat.push(<Route key={v.title} {...v}/>)
            })
        }
    })
    return <Router>{flat}</Router>
};


export const RouterFlat = (() => {
    const flat = [];
    [...Done, ...Dev,...INVISIBLE].forEach(v => {
        const {child, ...other} = v
        flat.push(other)
        if ( child ) {
            child.forEach(v => {
                flat.push(v)
            })
        }
    })
    return flat
})();