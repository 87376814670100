// bridge 自带多链，独立于 contract 配置
import {
    // OnBlock,
    GetGlobalSigner,
    ResetGlobalSigner,
    SetDefault,
    DEFAULT_ACCOUNT,
    DEFAULT_CHAIN_ID
// } from './e'
} from 'ethers-easy'


import { useEffect, createContext, useContext, useState, } from "react";

let _blockListenList = new Map()
let _lastBlock = 0
function useEthersProvider({ chainId: _chainId, rpc}) {
    const [{ account, chainId, unlock, wallet}, setChain] = useState(() => {
        SetDefault({rpc, chainId: _chainId})
        return {
            account: DEFAULT_ACCOUNT,
            chainId: _chainId || DEFAULT_CHAIN_ID,
            unlock: false, // false 未解锁， true 已解锁
            wallet: null // null 未安装钱包， string 钱包名称
        }
    })
    // 初始化本地钱包
    useEffect(() => {
        let _polling = true
        let _oldAccount = DEFAULT_ACCOUNT
        let _oldChainId_ = _chainId
        SetDefault({rpc, chainId: _chainId})
        
        const init = async () => {
            let signer = ResetGlobalSigner()
            if (_polling === false) return
            if ( signer.provider.provider === undefined ) {
                // 未安装钱包
                if ( _oldChainId_ !== _chainId || _oldAccount !== DEFAULT_ACCOUNT ) {
                    setChain({
                        account: DEFAULT_ACCOUNT,
                        chainId: _chainId,
                        unlock: false,
                        wallet: null
                    })
                    _oldAccount = DEFAULT_ACCOUNT
                    _oldChainId_ = _chainId
                }
            }
            else {
                // 已安装钱包
                try {
                    let [
                        _newAccount,
                        _newChainId,
                        _newBlockNumber
                    ] = await Promise.all([
                        signer.getAddress(),
                        signer.getChainId(),
                        signer.provider.getBlockNumber()
                    ])
                    // block 修改在此处 可以和 chainId 修改同步，当 chainId 修改时，setChain 会导致页面刷新，此时 block 同步更新， _blockListenList 不会出发
                    // 通过 setChain 出发 下级的 getBlockNumber，做到同步更新 且 减少刷新次数
                    if ( _oldChainId_ !== _newChainId || _oldAccount !== _newAccount ) {
                        _lastBlock = _newBlockNumber
                        _newAccount = !_newAccount ? DEFAULT_ACCOUNT : _newAccount
                        // _newAccount = '0xfE9EbCbb134b6d4c1DD201Cac9F5e680FB116C4B'
                        setChain({
                            account: _newAccount, // 测试 地址 在这里修改
                            chainId: _newChainId,
                            unlock: _newAccount !== DEFAULT_ACCOUNT,
                            wallet: signer.provider.connection.url
                        })
                        _oldAccount = _newAccount
                        _oldChainId_ = _newChainId
                    }

                    // 监听区块，切换 chainId 时，OnBlock 会报错 提示
                    // 这里不使用 setBlock hook, 因为 setBlock 会导致页面刷新
                    if ( _lastBlock !== _newBlockNumber ) {
                        _lastBlock = _newBlockNumber
                        _blockListenList.forEach((_,v) => v(_newBlockNumber))
                    }
                    setTimeout(init, 1000)
                } catch (error) {
                    // 切换网络时，会报错，此时需要重新初始化
                    console.log(error.message)
                    const errorInfo = error.message
                    // 切换网络时，会报错，此时需要重新初始化
                    if (errorInfo.match("underlying network changed")) {
                        signer = ResetGlobalSigner()
                        init()
                    }
                    else if (errorInfo.match("unknown account")) {
                        // signer = ResetGlobalSigner()
                        setTimeout(init, 1000)
                        // init()
                    }
                    // 其它报错 不再循环
                }
            }
        }
        init()

        // const {stop} = OnBlock(signer.provider, (_fromBlock, _toBlock) => {
        //     _lastBlock = _toBlock
        //     _blockListenList.forEach((_,v) => v(_toBlock))
        // })
        return () => {
            _polling = false
            _blockListenList = new Map()
            _lastBlock = 0
            // stop()
        }
    }, [_chainId, rpc])

    const addBlockListen = (cb) => {
        // 开发者模式时 StrictMode 导致出现重复添加的情况，是正常的
        if (_blockListenList.get(cb)) return
        _blockListenList.set(cb, true)
    }

    const removeBlockListen = (cb) => {
        if (_blockListenList.has(cb)) {
            _blockListenList.delete(cb)
        }
    }
    const connect = () => {
        if (wallet || !unlock) {
            const signer = GetGlobalSigner()
            signer.provider.provider.enable()
            ResetGlobalSigner()
        }
    } 

    return {
        account,
        chainId,
        unlock,
        wallet,
        addBlockListen,
        removeBlockListen,
        connect
    }
}

const Context = createContext({})

function useEthers() {
    const pro = useContext(Context)
    const [, setBlockNubmer] = useState(_lastBlock)

    const getBlockNumber = () => {
        pro.addBlockListen(setBlockNubmer)
        return _lastBlock
    }

    useEffect(() => {
        return () => {
            pro.removeBlockListen(setBlockNubmer)
        }
    },[])

    return {
        ...pro,
        getBlockNumber
    }
}

function EthersProvider({chainId, rpc, children }) {
    const pro = useEthersProvider({ chainId, rpc })
    return(
        <Context.Provider
            value={pro}
        >
            {children}
        </Context.Provider>
    )
}

export default EthersProvider

export {
    Context,
    useEthers
}